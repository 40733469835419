.introduction_page_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introduction_page_panel {
  width: 600px;
  height: 400px;
  background-color: white;
  border: 1px solid grey;
  filter: drop-shadow(0 0 0.2rem grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.introduction_page_image {
  width: 150px;
}

.connecting_buttons_container {
  display: flex;
  flex-direction: column;
}

.join_room_button {
  background-color: #2d8cff;
  border: 1px solid #e5e5e5;
  color: white;
  font-size: 13px;
  font-weight: 700;
  width: 180px;
  height: 30px;
  border-radius: 8px;
  transition: 0.3s;
}

.join_room_button:hover {
  background-color: blue;
}

.create_room_button {
  margin-top: 15px;
  background-color: white;
  border: 1px solid #e5e5e5;
  color: black;
  font-size: 13px;
  width: 180px;
  height: 30px;
  border-radius: 8px;
  transition: 0.3s;
}

.create_room_button:hover {
  background-color: #e5e5e5;
}
